@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "gilroy-light";
  src: url("../public/assets/fonts/Gilroy-Light.otf");
}

@font-face {
  font-family: "gilroy-regular";
  src: url("../public/assets/fonts/Gilroy-Regular.ttf");
}


@font-face {
  font-family: "gilroy-semibold";
  src: url("../public/assets/fonts/Gilroy-SemiBold.ttf");
}

@font-face {
  font-family: "gilroy-bold";
  src: url("../public/assets/fonts/Gilroy-Bold.ttf");
}

@font-face {
  font-family: "gilroy-extra-bold";
  src: url("../public/assets/fonts/Gilroy-ExtraBold.otf");
}

@font-face {
  font-family: "sharpgrotesk-book";
  src: url("../public/assets/fonts/SharpGrotesk-Book20.ttf");
}

@font-face {
  font-family: "sharpgrotesk-medium";
  src: url("../public/assets/fonts/SharpGrotesk-Medium20.ttf");
}

@font-face {
  font-family: "sharpgrotesk-bold";
  src: url("../public/assets/fonts/SharpGrotesk-Bold25.otf");
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.22) !important;
}

.PhoneInputCountryIcon--border {
  background-color: transparent !important;
  box-shadow: none !important;
}

:root {
  --PhoneInputCountryFlag-height: 1.5em !important;
}
